import axios from 'axios';
import { API_KEY, HOST_DEV } from "../constants";

export function requestUrl({ query, url }) {

    let params = []
  
    if (query) params.push(query);
  
    if (params.length) {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += params.join('&');
    }
      
    if (window.location.hostname == "localhost") {
      return HOST_DEV + url
    }

    return process.env.REACT_APP_API_HOST + url
  }

export function dispatch(options) {
    let headers_config = {
      "Content-Type": "application/json;charset=utf-8",
      'Nonce-Key': API_KEY,
    }

    if(options.contentType) {
      headers_config["Content-Type"] = options.contentType;
    }
  
    if (options.token) {
      headers_config["Authorization"] = `Bearer ${options.token}`;
    }
  
    let config = {
      method: options.method || "get",
      url: requestUrl(options),
      headers: headers_config
    }
  
    if (options.body) {
      config["data"] = options.body;
    }
    if (options.responseType) {
      config["responseType"] = options.responseType;
    }

    return axios(config);
  }
  