import React, { Component } from 'react';
import { Container } from "react-bootstrap";
import { SocialMedia } from "./SocialMedia";
import logo from "../../../assets/img/logo.png";

export const Header = ({socialnav}) => {  return (
    <header>
      <div className={`container-lg ${socialnav ? '' : 'no-social'}`}>
        <div className='d-flex justify-content-between align-items-center py-5'>
          <a className="navbar-brand my-1" href="/"><img src={logo} className='img-fluid'/></a>
          {socialnav ? <SocialMedia /> : null}
        </div>
      </div>
    </header>
  );
}
