import React from "react";
import { Redirect } from "react-router-dom";

import Presentation from "./views/pages/presentation/Presentation";
import Survey from "./views/pages/survey/Survey";
import SurveyThankYou from "./views/pages/survey/ThankYou";

export const Routes = {
    Home: { path: "/" },
    Presentation: { path: "/presentation" },
    Survey: { path: "/survey" },
    SurveyThankYou: { path: "/survey/thank-you" }
}

export default [
    {
        name: "Home",
        path: Routes.Home.path,
        showHeader: true,
        showSocialNav: true,
        showFooter: true,
        footerVersion: '1',
        element: < Presentation />
    },
    {
        name: "Presentation",
        path: Routes.Presentation.path,
        exact: true,
        showHeader: true,
        showFooter: false,
        showSocialNav: true,
        element: < Presentation />
    },
    {
        name: "Survey",
        path: Routes.Survey.path,
        exact: true,
        showHeader: true,
        showFooter: false,
        showSocialNav: false,
        footerVersion: '1',
        element: < Survey />
    },
    {
        name: "SurveyThankYou",
        path: Routes.SurveyThankYou.path,
        exact: true,
        showHeader: true,
        showFooter: true,
        showSocialNav: false,
        footerVersion: '2',
        element: < SurveyThankYou />
    },
]