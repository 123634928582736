import { React, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Alert } from "reactstrap";
import { useTranslation } from 'react-i18next';

import { Routes } from "../../../routes";
import { QuestionsList } from "../../components/survey/QuestionsList";
import { QuestionnaireContext } from "../../../core/contexts/QuestionnaireContext";

const Survey = () => {
    const { t } = useTranslation()
    const { questionnaire, isLoading, error } = useContext(QuestionnaireContext)

    useEffect(() => {
        document.body.classList.add('page-survey');

        return () => {
            document.body.classList.remove('page-survey')
        }
    }, []);

    const navigate = useNavigate();

    const hanndleFinish = () => {
        navigate(Routes.SurveyThankYou.path)
    }

    return (
        <div className="container-xl">
            {questionnaire?.items?.length != undefined && <QuestionsList questions={questionnaire?.items ?? []} hanndleFinishSurvey={hanndleFinish} />}
            {questionnaire?.items?.length == undefined && error && !isLoading &&
                <div className="bg-white rounded-3 p-4 pb-5 shadow container-encuenta">
                    <div className="content-questions mx-auto">
                        <Alert color="danger">
                            {t('survey.error.load_survey')}
                        </Alert>
                    </div>
                </div>
            }
        </div>
    )
}

export default Survey;