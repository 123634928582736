import React, { useContext, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/App.css';
import '../assets/css/styles.scss';
import '../assets/css/styles.css';

import routes from "../routes";
import { Footer } from "./components/common/Footer";
import { Header } from "./components/common/Header";

import { QuestionnaireContext } from "../core/contexts/QuestionnaireContext";

const App = () => {
  const { i18n } = useTranslation();
  const lenguageLoaded = useRef(false)
  const { questionnaire } = useContext(QuestionnaireContext)
  const defaultRouteProps = {
    showHeader: true,
    showFooter: true,
    showSocialNav: true,
    footerVersion: '1',
  }

  useEffect(() => {
    if(questionnaire?.language && !lenguageLoaded.current) {
      lenguageLoaded.current = true
      i18n.changeLanguage(questionnaire.language)
    }

    return () => {
      sessionStorage.setItem('last_lenguage', questionnaire.language);
    }
    
  }, [questionnaire])

  const params = new URLSearchParams(window.location.search);

  const questionnaireToken = params.get('token');

  if(questionnaireToken) {
    sessionStorage.setItem('current_questionnaire_token', questionnaireToken);
  }

  return (
    <React.Fragment>

      <Routes>
        {routes.map((route, index) => {
          const routeProps = {
            ...defaultRouteProps,
            ...route
          }

          return (
            <Route key={'route-item-' + index} {...routeProps} 
              element={
                <React.Fragment>
                  {routeProps.showHeader && <Header socialnav={routeProps.showSocialNav} />}
                  <main>{routeProps.element}</main>
                  {routeProps.showFooter && <Footer version={routeProps.footerVersion} />}
                </React.Fragment>
              }
            >
            </Route>
          )
        })}
      </Routes>
    </React.Fragment>
  );
};

export default App;