import React from 'react';
import { useTranslation } from 'react-i18next';

import mundo from '../../../assets/img/mundo.svg';
import { SocialMedia } from './SocialMedia';

export const Footer = ({version}) => {
    const { t } = useTranslation();

    return (
      <footer className='pt-3'>
        {version === '1' ? 
          <div className='container-lg py-2 v1'>
              <p className='mb-0'><small> © Copyright Ghidini Rodil 2023. {t('footer.rigths')} </small></p>
          </div>
        :
          <div className='container-md py-2 v2 text-center'>
            <div className='text-primary'>
              <p className='fw-500'>{t('footer.follow_us_on')}:</p>
              <div className='mb-3'><SocialMedia/></div>
              <i className='me-2'><img src={mundo}/></i>
              <a href='https://www.ghidinirodil.com' target='_blank' className='fw-semibold'>ghidinirodil.com</a>
            </div>
            <hr/>
            <p className='mb-0 text-center text-copyright'><small> © Copyright Ghidini Rodil 2023. {t('footer.rigths')} </small></p>
          </div>
        }
      </footer>
    );
}