import React from "react";
import { Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faLinkedinIn, faFacebook } from '@fortawesome/free-brands-svg-icons';

export const SocialMedia = () => {
    return (
        <Nav>
            <NavItem>
                <NavLink href="https://www.linkedin.com/company/ghidini-rodil/" target="_blank"><FontAwesomeIcon className="fa-2xl" color="#958BA1" icon={faLinkedinIn} /></NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="https://www.youtube.com/@GhidiniRodil" target="_blank"><FontAwesomeIcon className="fa-2xl" color="#958BA1" icon={faYoutube} /></NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="https://www.facebook.com/GhidiniRodil" target="_blank"><FontAwesomeIcon className="fa-2xl" color="#958BA1" icon={faFacebook} /></NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="https://www.instagram.com/ghidinirodil/?igshid=YmMyMTA2M2Y%3D" target="_blank" className="pe-lg-0"><FontAwesomeIcon className="fa-2xl" color="#958BA1" icon={faInstagram} /></NavLink>
            </NavItem>
        </Nav>
    )
}